import React from "react";
import Layout from "../components/Layout";
import PricingTable from "../components/PricingTable";
import {Link} from "gatsby";

function Prices() {
    return (
        <Layout>
            <div className="services_section_banner">
                <div className="services_banner"
                     style={{backgroundImage: `url(https://images.pexels.com/photos/48195/document-agreement-documents-sign-48195.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`}}>
                    <h1>Políticas de calidad</h1>
                </div>
            </div>
            <div className="inner" style={{marginTop: '1rem'}}>
                <section className="tiles">
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Operamos con Objetividad, Independencia, Integridad y Honestidad:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Hacemos lo correcto</li>
                                <li>Trabajamos con rectitud y veracidad</li>
                                <li>Asesoramos con responsabilidad</li>
                                <li>Actuamos con profesionalismo</li>
                                <li>Somos transparentes frente a nuestros grupos de interés</li>
                            </ul>
                        </div>
                    </article>
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Marcamos la diferencia:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Nos mantenemos actualizados</li>
                                <li>Nunca dejamos de aprender y de mejorar</li>
                                <li>Estamos pendientes a los cambios normativos</li>
                                <li>Cuidamos a nuestro equipo</li>
                                <li>Reconocemos el trabajo que desempeña cada uno de nuestros colaboradores</li>
                                <li>Valoramos el esfuerzo, la entrega y la pasión que ponen el día a día</li>
                                <li>Apoyamos a otros a su crecimiento personal y profesional</li>
                                <li>Nos respetamos entre todos</li>
                            </ul>
                        </div>
                    </article>
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Nos preocupamos por nuestros clientes:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Creamos soluciones que permitan a nuestros clientes tomar mejores decisiones</li>
                                <li>Cuidamos sus intereses</li>
                                <li>Nos encargamos de blindar las áreas clave de su negocio</li>
                            </ul>
                        </div>
                    </article>
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Respetamos la confidencialidad de todos:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Tenemos claro que la confianza es nuestro principal objetivo</li>
                                <li>Sabemos que la información es poder, por ello la información de nuestros clientes y
                                    colaboradores, se mantiene como privada y solo estos pueden decidir con quien
                                    compartirla
                                </li>
                                <li>La información es tratada solo por personal autorizado</li>
                            </ul>
                        </div>
                    </article>
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Trabajamos como un solo equipo:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Juntos ponemos nuestro compromiso, esfuerzo y dedicación</li>
                                <li>Buscamos crecer mano a mano</li>
                                <li>Compartimos una red de relaciones de negocio</li>
                            </ul>
                        </div>
                    </article>
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Ofrecemos una excelente calidad de servicio a nuestros clientes:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Nos aseguramos de que los servicios profesionales que se le están brindando, se
                                    estén ejecutando de forma satisfactoria
                                </li>
                                <li>Contamos con personal calificado</li>
                                <li>Empleamos buenas practicas en el desarrollo de su información</li>
                            </ul>
                        </div>
                    </article>
                    <article className="blog-card">
                        <div className="description" style={{flexBasis: '100%'}}>
                            <h1 style={{fontSize: 'larger'}}>Nos reinventamos para mejorar:</h1>
                            <ul style={{color: '#000000'}}>
                                <li>Trabajos con sistemas de vanguardia que nos permite agilizar la manera de
                                    contabilizar y operar
                                </li>
                                <li>Utilizamos herramientas tecnológicas que nos permiten ser más eficientes</li>
                                <li>Adoptamos fácilmente los sistemas de nuestros clientes</li>
                            </ul>
                        </div>
                    </article>
                </section>
            </div>
        </Layout>
    )
}

export default Prices
